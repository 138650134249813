import React from "react"
import { Link } from "gatsby"
import arrowDark from "../assets/icons/arrow-dark.svg"
import arrowLight from "../assets/icons/arrow.svg"


const FeatureCardMd = (props) => {
  return (
          <Link to={`/features/${ props.content.slug }`} data-sal="fade" data-sal-delay={props.content.delay} data-sal-easing="ease" data-sal-duration="200">
            <div className={`feature-card-md position-relative ${props.style.color}`}>
              <img src={arrowLight} className="icon-arrow icon-arrow__light" alt="arrow light"/>
              <img src={arrowDark} className="icon-arrow icon-arrow__dark" alt="arrow dark"/>
              <div className="feature-card-md__img-container">
                <img src={`https://taact-brand-assets.netlify.app/assets/icons/jetti/${props.content.icon}.svg`} className="feature-card-md__img feature-card-md__img-dark" alt="feature icon"/>
                <img src={`https://taact-brand-assets.netlify.app/assets/icons/jetti/${props.content.icon}-reverse.svg`} className="feature-card-md__img feature-card-md__img-light" alt="feature icon"/>
              </div>
              <p>{props.content.title}</p>
            </div>
          </Link>
    )
}

export default FeatureCardMd
